import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/ModalProvider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Accordion.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Alert.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Box.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Button.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Calendar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Card.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ClientOnly.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfirmationModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/EmptyMessage.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/FieldErrorMessage.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/FullscreenSpinner.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Icon.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Input.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Link.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/LottieAnimation.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Menu.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/MenuButton.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/NavigationLink.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Popover.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Select.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/SelectField.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Skeleton.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Spinner.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Table.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tabs.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tag.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Text.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Textarea.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/TextField.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Thumbnail.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Title.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Toast.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tooltip.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/AppMenu/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/BarcodeScannerWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/CashRegisterUpsellModalWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/EnsureSubscriptionWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/InsufficientStockInCartModalWidget/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/PlanUpsellWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/PricingPlans/AvailablePlansWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/PricingPlans/PricingPlanCardWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/ScreenResolutionLimiter.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/SidebarWidget/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/SubscriptionBannerWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/widgets/UnpublishedItemsInCartModalWidget/index.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.4.3_next@14.1.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js")